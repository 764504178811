import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import Shoot from "./shoot"
import { postLogDetails } from "../../../Services/APIs/appDetails";


const FullScan = () => {

    useEffect(() => {
        if (localStorage.getItem("response")) {
            navigate('/sim')
        }
        const storedUser = localStorage.getItem("usertpi");
        const user = storedUser ? JSON.parse(storedUser)?.usertpi : null;
        if (!user) {
            navigate("/sim")
        } else {
            postLogDetails({ app: "simmetry", payLoad: "On demo screen", uid: user })
        }
    }, [])

    const [isToothPicker, setToothPicker] = useState(true)
    const [activeImageSide, setImageSide] = useState(0)
    const navigate = useNavigate()


    return (
        <div className="h-full">
            {
                // isToothPicker ?
                // <ToothSelector setImageSide={setImageSide} setToothPicker={setToothPicker} /> :
                <Shoot activeImageSide={activeImageSide} setToothPicker={setToothPicker} quickScan={false} setImageSide={setImageSide} />
            }
        </div>
    )
}

export default FullScan