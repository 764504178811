import { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./index.css";
import { useNavigate } from "react-router-dom";
import ReshootPopup from "./reshoot";
import { imageUpload, submitSimmetryScan, simmetryImageUpload } from "../../../Services/APIs/scan";
import dataURItoBlob from "../../../Utils/dataUriToBlob";
import { encrypt } from "../../../Utils/hmac";
import { getCroppedImg } from "../../../Utils/cropImageHelper";
import { postLogDetails } from '../../../Services/APIs/appDetails';
import AboutMeForm from "../Form/aboutMeForm";
import oval from "../../../assets/Icons/oval.png"
import ImageProcessing from "../../../assets/simmetry/image-processing.png";
import TeethWhitening from "../../../assets/simmetry/teeth-whitening.png";

const Preview = (props) => {
    const { image, setCamera, onClose, setDemo } = props;
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [reshoot, setReshoot] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [errorType, setImageError] = useState('')
    const navigate = useNavigate();
    const usertpiData = localStorage.getItem("usertpi");
    const parsedUsertpi = usertpiData ? JSON.parse(usertpiData) : {};
    const folderName = parsedUsertpi.usertpi || "";
    const [cropStatus, setCropStatus] = useState(false);
    const [croppedImage, setCroppedImage] = useState(null);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [showFullPreview, setShowFullPreview] = useState(false); // New state for full-screen preview
    const [croppedBlob, setCroppedBlob] = useState();
    const [progress, setProgress] = useState(10); // Start at 10%
    const [isIframeOpen, setIsIframeOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isApiError, setIsApiError] = useState(true);
    const [info, setInfo] = useState("Checking for photo quality...")
    const [isFormSubmitted, setIsFormSubmitted] = useState(() => {
        return localStorage.getItem("is_form") === "true";
    });

    const toggleActiveState = () => {
        localStorage.setItem("is_form", "true");
        setIsFormSubmitted(true); // Update React state
        setIsIframeOpen(false);
        setProgress(10);
    };


    useEffect(() => {
        if (!isLoading && !reshoot && isFormSubmitted && !isIframeOpen && !isActive && !isApiError) {
            navigate('/sim/result');
        }
    }, [isLoading, reshoot, isIframeOpen, isFormSubmitted, isApiError]);


    useEffect(() => {
        if (isActive && !isIframeOpen && isLoading) {
            const interval = setInterval(() => {
                setProgress((prev) => {
                    if (prev < 100) {
                        return prev + 1;
                    }
                    clearInterval(interval);
                    return prev;
                });
            }, 250);

            return () => clearInterval(interval);
        }
    }, [isActive, isIframeOpen, isLoading]);

    const submit = async () => {
        setLoading(true);
        setIsActive(true)
        const usertpiData = localStorage.getItem("usertpi");
        const parsedUsertpi = usertpiData ? JSON.parse(usertpiData) : {};
        const uid = parsedUsertpi.usertpi || "";
        const folderName = parsedUsertpi.usertpi || "";

        if (!uid || !folderName) {
            setLoading(false);
            return;
        }

        const data = { uid, folderName, company: "bearn" };
        const encrypted = encrypt(data);

        if (!image) {
            setLoading(false);
            return;
        }

        const file = new File([croppedBlob], "report_image.jpeg", { type: "image/jpeg", });

        const formData = new FormData();
        formData.append("report_image", file);
        formData.append("_n", encrypted);

        setReshoot(false);

        try {
            const response = await simmetryImageUpload({ formData }).then(async (res) => {
                if (res.data.data.is_reshoot) {
                    setLoading(false)
                    setIsActive(false)
                    setProgress(10)
                    setImageError('')
                    setReshoot(true)
                } else {
                    localStorage.setItem("response", JSON.stringify(res.data.data));
                    localStorage.getItem("is_form") ? setIsIframeOpen(false) : setIsIframeOpen(true)
                    const info = { uid, folderName, company: "bearn", imageName: res.data.data.imageName };
                    setInfo("AI Processing...")
                    await submitSimmetryScan(info)
                        .then(async (response) => {
                            // throw new Error("Simulated API error"); // Forcefully throw an error to trigger catch

                            if (response.data.data.is_reshoot) {
                                setProgress(10)
                                setImageError('')
                                setReshoot(prev => true)
                                setLoading(prev => false)
                                setIsActive(prev => false)
                            } else {
                                setIsApiError(prev => false)
                                setLoading(prev => false);
                                setIsActive(prev => false);
                                setReshoot(prev => false);
                                if (localStorage.getItem("is_form")) {
                                    toggleActiveState()
                                }
                                // navigate('/sim/result')
                            }
                        })
                        .catch((err) => {
                            setLoading(false);
                            setIsApiError(true)
                            setImageError(err.status === 500 ? "ML failure" : '')
                            setProgress(10)
                            setReshoot(true)
                            setIsActive(false)
                        });
                }
            }).catch(err => {
                setImageError(err.status === 500 ? "ML failure" : '')
                postLogDetails({ uid, payLoad: `image upload error ${JSON.stringify(err)}` })
                setProgress(10)
                setReshoot(true)
                setIsApiError(true)
                setIsActive(false)
            })
        } catch (error) {
            postLogDetails({ uid, payLoad: `image upload error ${JSON.stringify(error)}` })
            setProgress(10)
            setImageError('')
            setIsApiError(true)
            setReshoot(true);
        } finally {
            setLoading(false);
        }
    };

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImgBlob = await getCroppedImg(image, croppedAreaPixels); // Pass croppedAreaPixels to the helper function
            const fileUrl = URL.createObjectURL(croppedImgBlob);
            setCroppedBlob(croppedImgBlob);
            setCroppedImage(fileUrl);
            setCropStatus(true);
            setShowFullPreview(true); // Show full-screen preview
        } catch (error) {
            setLoading(true);
        } finally {
            setLoading(false);
        }
    }, [image, croppedAreaPixels]);

    useEffect(() => {
        showCroppedImage();
    }, []);

    return (
        <>
            {isSuccess ? (
                <div>Whiten success</div>
            ) : (
                <>
                    {
                        !localStorage.getItem("is_form") && isIframeOpen &&
                        <AboutMeForm isIframeOpen={isIframeOpen} toggleActive={toggleActiveState} />
                    }
                    {
                        !isLoading && !isIframeOpen &&
                        <div className="w-screen h-screen flex flex-col overflow-hidden">
                            <p className="text-center mt-1 text-lg font-jost text-[#022022] w-full sm600:text-2xl">
                                Preview
                            </p>

                            {/* Image container with controlled height and aspect ratio */}
                            <div className="flex-grow flex justify-center items-center px-4 max-h-[70vh]">
                                <div className="relative w-full h-full flex justify-center items-center">
                                    <img
                                        src={image}
                                        alt="Cropped Preview"
                                        className="max-w-full max-h-full rounded-3xl object-contain border-4 border-blue-50"
                                    />
                                </div>
                            </div>

                            {/* Button container fixed to the bottom without causing overflow */}
                            <div className="w-full mt-3 flex flex-col justify-center items-center pb-6">
                                <button
                                    className="w-44 rounded-full text-white bg-[#011E41] font-jost font-medium text-base tracking-[4%] py-2 px-2"
                                    onClick={submit}
                                    disabled={isLoading}
                                >
                                    CONTINUE
                                </button>
                                <button
                                    className="w-44 mt-3 rounded-full font-jost font-medium text-base tracking-[4%] text-[#011E41] py-2 px-2 bg-[#EDE85E]"
                                    onClick={() => setCamera(true)}
                                >
                                    RETAKE
                                </button>
                            </div>
                        </div>
                    }
                    {
                        isActive && !isIframeOpen &&
                        <div>
                            <div className="flex items-center justify-center min-h-screen bg-gray-100">
                                <div className="flex flex-col items-center space-y-6">
                                    {/* Circle image container */}
                                    <div className="relative">
                                        <div className="w-32 h-32 rounded-full overflow-hidden">
                                            <img
                                                src={localStorage.getItem("is_form") ? TeethWhitening : ImageProcessing} // Replace with your image URL
                                                alt="Avatar"
                                                className="w-full h-full object-cover"
                                            />
                                        </div>
                                    </div>

                                    {/* Percentage Text */}
                                    <div className="text-xl font-semibold">{progress}%</div>

                                    {/* Gradient Progress Bar */}
                                    <div className="w-64 h-2 bg-gray-200 rounded-full">
                                        <div
                                            className="h-full rounded-full"
                                            style={{
                                                width: `${progress}%`,
                                                background: `linear-gradient(to right, #011E41, #8BB8E8)`,
                                            }}
                                        ></div>
                                    </div>

                                    {/* Text */}
                                    <div className="text-lg text-gray-700 font-jost">
                                        {localStorage.getItem("is_form") ? 'Processing Teeth Whitening...' : 'Validating your image...'}</div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        reshoot && !isIframeOpen && <ReshootPopup setReshoot={setReshoot} errorType={errorType} setCamera={setCamera} setDemo={setDemo} />
                    }
                </>
            )}
        </>
    );
};

export default Preview;