import { useState } from "react";
import { useNavigate } from "react-router-dom";

import demoImage from "../../../assets/demoImages/demoImage.jpg";
import tapIcon from "../../../assets/Icons/tap_icon.png"

const DemoScreens = (props) => {
    const { activeImageSide, setToothPicker, setDemo, setCamera, quickScan } =
        props;
    const gotIt = () => {
        setDemo(false);
        setCamera(true);
    };

    return (
        <>
            {
                <div className="bg-[#F6F6F6] h-full w-full ">
                    <div className=" h-3/5 w-full pt-4 px-2 bg-[#F6F6F6] ">
                        <img src={demoImage} alt="ai-teeth" className="h-full w-full object-contain  " />
                    </div>
                    <div className="h-2/5  bg-[#F6F6F6] ">
                        <div className=" h-full w-full flex flex-col justify-around px-8">
                            <div className=" font-jost text-lg font-normal text-[#022022]  text-center px-10  flex-grow py-4">Align your head inside the circle, flash that big smile</div>


                            <div className="  text-center py-8">
                                <button
                                    type="button"
                                    className="font-jost  font-medium text-[#022022] border border-[#022022] px-5 rounded-full text-2xl	py-2 "
                                    onClick={() => {
                                        gotIt()
                                    }}
                                >
                                    GOT IT
                                </button>
                            </div>


                        </div>
                    </div>


                </div>
            }
        </>
    );
};

export default DemoScreens;