import { postLogDetails } from "../Services/APIs/appDetails";

export const getCroppedImg = async (imageSrc) => {
    // Helper function to detect mobile devices
    const isMobile = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
            (window.innerWidth <= 768);
    };

    const createImage = (url) =>
        new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve(image);
            image.onerror = (error) => reject(error);
            image.src = url;
        });

    try {
        // Load the image
        const image = await createImage(imageSrc);

        // Create a canvas with the original dimensions
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Set canvas to original image dimensions
        canvas.width = image.width;
        canvas.height = image.height;

        // Draw the full image onto the canvas (no cropping)
        ctx.drawImage(
            image,
            0, 0, // Start at top-left corner of source
            image.width, image.height, // Use full width and height from source
            0, 0, // Draw at top-left corner of canvas
            image.width, image.height // Use full width and height on canvas
        );

        // Determine compression quality based on device and image size
        const isMobileDevice = isMobile();
        const isLargeImage = image.width * image.height > 2000000; // More than 2MP

        // Set quality based on device and image size
        let quality = 0.8; // Default high quality

        if (isMobileDevice && isLargeImage) {
            quality = 0.7; // More aggressive compression for large images on mobile
        } else if (isMobileDevice || isLargeImage) {
            quality = 0.8; // Medium compression for either mobile or large images
        }

        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    console.error('Canvas is empty');
                    throw new Error('Failed to create image blob');
                }

                // Log the compression result for debugging
                // console.log(`Image compressed: ${image.width}x${image.height}, Size: ${Math.round(blob.size / 1024)}KB, Quality: ${quality}`);
                postLogDetails({
                    app: "simmetry",
                    payLoad: ` Image compressed: ${image.width}x${image.height}, Size: ${Math.round(blob.size / 1024)}KB, Quality: ${quality}`,
                    uid: JSON.parse(localStorage.getItem("usertpi")).usertpi
                });
                resolve(blob);
            }, 'image/jpeg', quality); // Add quality parameter for compression
        });
    } catch (error) {
        postLogDetails({
            app: "simmetry",
            payLoad: `Error processing image: ${JSON.stringify(error)}`,
            uid: JSON.parse(localStorage.getItem("usertpi")).usertpi
        });
        // console.error('Error processing image:', error);
        throw error;
    }
};