import { Route, Routes } from "react-router-dom"
import LandingPage from "./LandingPage/index"
import FullScan from "./Scan/fullScan"
import Result from "./Scan/result";
import ProtectedRoute from "./protectedRoute";

const Simmetry = () => {
    return (
        <div className="h-full">
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route element={<ProtectedRoute />}>
                    <Route path="scan" element={<FullScan />} />
                    <Route path="result" element={<Result />} />
                </Route>
            </Routes>
        </div>
    )
}

export default Simmetry