import React, { useEffect } from "react";
import img2 from "../../../assets/simmetry/landingImage.jpg";
import img4 from "../../../assets/simmetry/landingImg4.jpg";
import logo from "../../../assets/simmetry/simmetry.png";
import { useNavigate } from "react-router-dom";
import { generateRandomAlphaNumeric } from "../../../Utils/alphaNumeric";
import { postLogDetails } from "../../../Services/APIs/appDetails";

const LandingPage = () => {

    const navigate = useNavigate();
    const takeScan = () => {
        const randomString = generateRandomAlphaNumeric(2);
        const timestamp = Date.now().toString();
        const randomString2 = generateRandomAlphaNumeric(1);
        const unique = randomString + timestamp + randomString2;
        localStorage.setItem("usertpi", JSON.stringify({ usertpi: unique }));
        postLogDetails({ app: "simmetry-ipad", payLoad: "visited landing page and started", uid: unique })
        navigate(`/sim-ipad/scan`);
    };

    useEffect(() => {
        localStorage.clear();
        // const hasReloaded = sessionStorage.getItem('hasReloaded');
        // if (!hasReloaded) {
        //     sessionStorage.setItem('hasReloaded', 'true');
        //     window.location.reload();
        // }
        const randomString = generateRandomAlphaNumeric(2);
        const timestamp = Date.now().toString();
        const randomString2 = generateRandomAlphaNumeric(1);
        const unique = randomString + timestamp + randomString2;
        localStorage.setItem("usertpi", JSON.stringify({ usertpi: unique }));
        postLogDetails({ app: "simmetry-ipad", payLoad: "visited landing page and started", uid: unique })
        navigate(`/sim-ipad/scan`);
    }, []);

    return (
        <div className="bg-white w-full h-full flex flex-col">
            <div className="h-1/2 relative w-full flex justify-end items-start">
                <img src={img2} alt="ai-teeth" className="h-full w-full absolute right-0" />
            </div>
            <div className="w-full bg-black -mt-8 relative">
                <img src={img4} alt="background-design" className="h-36 sm600:h-52 absolute right-0" />
            </div>
            <div className="flex-grow flex flex-col  py-8 justify-end">
                <div className="ml-8 py-8 ">
                    <p className="font-red-hat font-medium text-4xl  py-2 text-[#022022] ">
                        Virtual smile <br /> simulator
                    </p>

                    <p className="py-2 first-letter:font-red-hat text-[#8B8A97] text-xl ">
                        Smile, snap a selfie, and see <br /> the magic!
                    </p>
                </div>

                <div className="mt-2 w-full px-8 text-center">
                    <button
                        type="button"
                        className="text-base font-red-hat h-12 rounded-lg w-full text-white universal-button sm600:text-2xl"
                        onClick={() => {
                            takeScan();
                        }}
                    >
                        Transform Your Smile
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;