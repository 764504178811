import React, { useEffect, useState, useRef } from 'react'
import './index.css';
import { getSimmetryImages, simmetryNotificationMail } from '../../../Services/APIs/scan';
import { postLogDetails } from "../../../Services/APIs/appDetails";;


const Result = () => {
    const [selectedButton, setSelectedButton] = useState("Correction");
    const [progress, setProgress] = useState(50); // Default position at 50%
    const [isDragging, setIsDragging] = useState(false);
    const [imageWhiteningSrc, setWhiteningImageSrc] = useState(null);
    const [imageCropSrc, setCropImageSrc] = useState(null);
    const [imageStraightningSrc, setStraightningImageSrc] = useState(null);
    const [imageComboSrc, setComboImageSrc] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const containerRef = useRef(null);

    const handleSliderChange = (e) => {
        setProgress(e.target.value);
    };

    // Get image name from local storage safely
    const imageName = (() => {
        try {
            const response = localStorage.getItem("response");
            return response ? JSON.parse(response).imageName : null;
        } catch (e) {
            console.error("Error parsing response from localStorage:", e);
            return null;
        }
    })();

    const handleMouseDown = () => {
        setIsDragging(true); // Start dragging
    };

    // Get user data safely
    const uid = (() => {
        try {
            const storedUser = localStorage.getItem("usertpi");
            return storedUser ? JSON.parse(storedUser)?.usertpi : null;
        } catch (e) {
            console.error("Error parsing user data from localStorage:", e);
            return null;
        }
    })();

    // Helper function for safe image data conversion
    const safelyProcessImageData = (imageData, setImageState) => {
        if (!imageData?.data) return;

        try {
            // If it's already a string (possibly base64), use it directly
            if (typeof imageData.data === 'string') {
                setImageState(`data:image/jpeg;base64,${imageData.data}`);
                return;
            }

            // For ArrayBuffer or Uint8Array data, process in chunks
            const bytes = new Uint8Array(imageData.data);
            const chunkSize = 4096; // Smaller chunks for mobile
            let binary = '';

            for (let i = 0; i < bytes.byteLength; i += chunkSize) {
                const chunk = bytes.slice(i, Math.min(i + chunkSize, bytes.byteLength));
                binary += String.fromCharCode.apply(null, chunk);
            }

            const base64 = btoa(binary);
            setImageState(`data:image/jpeg;base64,${base64}`);
        } catch (err) {
            postLogDetails({
                app: "simmetry",
                payLoad: `Error processing image: ${err.message}`,
                uid
            });
            setError("Failed to process image data");
        }
    };

    // Load and process images from API
    useEffect(() => {
        if (!uid || !imageName) {
            setIsLoading(false);
            setError("Missing user ID or image name");
            return;
        }

        setIsLoading(true);

        getSimmetryImages(uid, imageName)
            .then((res) => {
                const { whiteningData, cropData, StraighteningData, correctionData } = res.data.data;
                console.log(whiteningData, cropData, StraighteningData)
                postLogDetails({ app: "simmetry", payLoad: `Response received`, uid });

                // Process each image separately with error handling
                safelyProcessImageData(whiteningData, setWhiteningImageSrc);
                safelyProcessImageData(cropData, setCropImageSrc);
                safelyProcessImageData(StraighteningData, setStraightningImageSrc);
                safelyProcessImageData(correctionData, setComboImageSrc);

                postLogDetails({ app: "simmetry", payLoad: "All images processed", uid });
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                setError("Failed to load images");
                postLogDetails({
                    app: "simmetry",
                    payLoad: `Error in result page ${err.message || JSON.stringify(err)}`,
                    uid,
                });
            });

    }, [uid, imageName]);

    // Ensure container dimensions match for consistent display
    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                const { width, height } = containerRef.current.getBoundingClientRect();
                setImageDimensions({ width, height });
            }
        };

        // Update on initial load
        updateDimensions();
        simmetryNotificationMail(false)
        // Update on window resize
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    // Preload images to get accurate dimensions
    useEffect(() => {
        if (!imageCropSrc || !imageWhiteningSrc || !imageStraightningSrc || !imageComboSrc) return;

        const preloadImages = () => {
            const preloadImageSources = [
                { name: "Whitening", src: imageWhiteningSrc },
                { name: "Crop", src: imageCropSrc },
                { name: "Correction", src: imageStraightningSrc },
                { name: "Combo", src: imageComboSrc }
            ];

            const preloadPromises = preloadImageSources.map(({ name, src }) => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.onload = () => resolve({ name, width: img.width, height: img.height });
                    img.onerror = reject;
                    img.src = src;
                });
            });

            Promise.all(preloadPromises)
                .then(dimensions => {
                    console.log("Preloaded Image Dimensions:", dimensions);

                    postLogDetails({
                        app: "simmetry",
                        payLoad: `All images preloaded with dimensions: ${JSON.stringify(dimensions)}`,
                        uid
                    });
                })
                .catch(err => {
                    console.error("Error preloading images:", err);
                    postLogDetails({
                        app: "simmetry",
                        payLoad: `Error preloading images: ${err.message || JSON.stringify(err)}`,
                        uid
                    });
                });
        };

        preloadImages();
    }, [imageCropSrc, imageWhiteningSrc, imageStraightningSrc, imageComboSrc, uid]);

    // Handle mouse and touch events for slider
    useEffect(() => {
        const handleMouseMove = (e) => {
            if (!isDragging) return;

            const container = document.querySelector('.stacked-images');
            if (!container) return;

            const rect = container.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const newProgress = Math.max(0, Math.min(100, (x / rect.width) * 100));

            setProgress(newProgress);
        };

        const handleMouseUp = () => {
            setIsDragging(false);
        };

        // Add touch support
        const handleTouchMove = (e) => {
            if (!isDragging) return;

            const container = document.querySelector('.stacked-images');
            if (!container) return;

            const rect = container.getBoundingClientRect();
            const touch = e.touches[0];
            const x = touch.clientX - rect.left;
            const newProgress = Math.max(0, Math.min(100, (x / rect.width) * 100));

            setProgress(newProgress);
            e.preventDefault(); // Prevent scrolling while dragging
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('touchmove', handleTouchMove, { passive: false });
        document.addEventListener('touchend', handleMouseUp);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('touchend', handleMouseUp);
        };
    }, [isDragging]);

    // Function to get current image source based on selected button
    const getCurrentImageSrc = () => {
        switch (selectedButton) {
            case "Whitening":
                return imageWhiteningSrc;
            case "Correction":
                return imageStraightningSrc;
            case "Combo":
                return imageComboSrc;
            default:
                return imageWhiteningSrc;
        }
    };

    return (
        <div className='h-screen flex flex-col'>
            <div className='text-center px-2 pt-8 font-medium text-lg font-jost tracking-wider text-[#022022]'>
                Your Smile Transformation Results
            </div>
            <div className='text-center  font-jost  text-[15px] font-normal text-[#022022] pb-4'>
                Compare Your Smile Before & After
            </div>

            <div className='mx-4 flex flex-row bg-[#EEEEEE] rounded-2xl justify-evenly border min-h-12 shadow-[inset_4px_4px_10px_rgba(0,0,0,0.3)]'>
                {["Correction", "Whitening", "Combo"].map((type) => (
                    <button
                        key={type}
                        className={`font-jost text-[15px] font-normal flex-grow flex items-center justify-center rounded-2xl transition-all duration-300 ${selectedButton === type ? "bg-[#011E41] text-[#FFFFFF]" : "text-[#022022]"}`}
                        onClick={() => {
                            postLogDetails({ app: "simmetry", payLoad: `clicked ${type} `, uid })
                            setSelectedButton(type)
                        }}
                    // disabled={type === "Combo"} // Disable Combo Button
                    >
                        {type}
                    </button>
                ))}
            </div>

            {/* Image comparing Section (Flex Grow) */}
            <div className="flex-grow m-4 flex flex-col">
                <div className="image-slider-container flex-grow flex flex-col">
                    <div className="image-container flex-grow relative" style={{ height: '400px' }}>
                        <div
                            ref={containerRef}
                            className="stacked-images relative w-full h-full rounded-xl"
                            style={{ maxHeight: '100%', overflow: "hidden" }}>

                            {isLoading ? (
                                <div className="w-full h-full flex items-center justify-center font-jost">Loading the results...</div>
                            ) : error ? (
                                <div className="w-full h-full flex items-center justify-center text-red-500 font-jost">{error}</div>
                            ) : (
                                <>
                                    <div className=" absolute top-0 left-0 w-full h-full rounded-xl">
                                        {imageCropSrc ? (
                                            <div className="w-full h-full" style={{ position: 'relative' }}>
                                                <img
                                                    src={imageCropSrc}
                                                    // src={getCurrentImageSrc()}

                                                    alt="After transformation"
                                                    className="w-full h-full object-contain"
                                                    loading="lazy"
                                                />
                                            </div>
                                        ) : (
                                            <div className="w-full h-full flex items-center justify-center">Image not available</div>
                                        )}
                                    </div>

                                    <div
                                        className="before-image absolute top-0 left-0 h-full"
                                        style={{
                                            width: `${progress}%`,
                                            overflow: 'hidden',
                                            borderRight: '1px solid white'
                                        }}
                                    >
                                        {getCurrentImageSrc() && (
                                            <div className="w-full h-full" style={{ position: 'relative' }}>
                                                <img
                                                    src={getCurrentImageSrc()}
                                                    alt="Before transformation"
                                                    className="absolute top-0 left-0 h-full object-contain"
                                                    style={{
                                                        width: `${100 / (progress / 100)}%`, // Scale proportionally to container
                                                        maxWidth: 'none'
                                                    }}
                                                    loading="lazy"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>

                        {/* {!isLoading && !error && (
                            <div
                                className="slider-handle absolute top-0 h-full w-0.5 bg-white cursor-ew-resize"
                                style={{
                                    left: `calc(${progress}% + 3px)`,
                                    transform: 'translateX(-50%)',
                                    zIndex: 10,
                                    boxShadow: '0 0 5px rgba(0,0,0,0.5)'
                                }}
                                onMouseDown={handleMouseDown}
                                onTouchStart={() => setIsDragging(true)}
                            ></div>
                        )} */}
                    </div>

                    <div className="mt-5 w-full px-4">
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={progress}
                            onChange={handleSliderChange}
                            className="slider w-full"
                            style={{ "--progress": `${progress}%` }}
                            disabled={isLoading || !!error}
                        />
                    </div>
                </div>

                <div className="px-5 pt-2 flex justify-between w-full">
                    <div className='font-jost font-normal text-xs '>Before</div>
                    <div className='font-jost font-normal text-xs '>After</div>
                </div>
            </div>

            <div className='text-center font-normal font-jost pb-2 text-[#022022] text-base'>
                Adjust Slider for Smile Options
            </div>

            <div className="mt-2 w-full px-8 text-center overflow-visible"
                onClick={() => postLogDetails({ app: "simmetry", payLoad: "Clicked Book Appointment", uid })}
            >
                <a href="http://consultationlt.smileexpress.com/" target="_blank" rel="noopener noreferrer">
                    <button
                        type="button"
                        className="text-base font-jost font-medium  rounded-full w-60 py-3 px-4  text-white bg-[#011E41] sm600:text-2xl tracking-wider"
                    >
                        BOOK APPOINTMENT
                    </button>
                </a>
            </div>

            <div className='px-12 font-normal text-xs text-center font-jost text-[#808F90] tracking-wider my-4 mb-8'>
                AI-generated results for informational purposes only. Outcomes may vary. Consult a professional for personalized advice.
            </div>
        </div>
    );
};

export default Result;







