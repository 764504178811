import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import 'react-html5-camera-photo/build/css/index.css';
import DemoScreens from './demoScreens';
import Preview from './preview';
import "./index.sim.css"
import PermissionPopup from './cameraPermissionPopup';
import { GetBrowserDetails } from '../../../Utils/deviceDetails';
import { GetMobileOperatingSystem } from "../../../Utils/getOS";
import FrontCameraError from './frontCameraError';
import arrow1 from "../../../assets/Icons/yellow-left-top.png"
import arrow2 from "../../../assets/Icons/yellow-right-top.png"
import arrow3 from "../../../assets/Icons/yellow-feft-bottom.png"
import arrow4 from "../../../assets/Icons/yellow-right-bottom.png"
import oval from "../../../assets/Icons/oval.png"

import { postLogDetails } from '../../../Services/APIs/appDetails';
import { notifyCameraError } from '../../../Services/APIs/scan';
import { getCompleteDeviceDetails } from '../../../Utils/comepleteDeviceInfo';

const Shoot = (props) => {
    const app = "simmetry"
    const [facialMode, setFacialMode] = useState(FACING_MODES.USER)
    const [frontCameraError, setFrontCameraError] = useState(false)
    const { activeImageSide, setToothPicker, quickScan, setImageSide } = props
    const navigate = useNavigate()
    const [isDemo, setDemo] = useState(true)
    const [cropped, setCropped] = useState(true)
    const [isCamera, setCamera] = useState(true)
    const [capturedImage, setCapturedImage] = useState()
    const [flashLight, setFlashLight] = useState(false)
    const [mediaStream, setStream] = useState(null)
    const [errorType, setErrorType] = useState({ isError: false, type: "ios" })
    const OS = GetMobileOperatingSystem()   // operating system
    const uid = (() => {
        try {
            const storedUser = localStorage.getItem("usertpi");
            return storedUser ? JSON.parse(storedUser)?.usertpi : null;
        } catch (e) {
            return null;
        }
    })();

    useEffect(() => {
        // Clean-up function to reset flashlight state when component unmounts or user navigates away
        return () => {
            if (mediaStream && flashLight) {
                setFlashLight(false)
                torchMechanism(mediaStream, false); // Turn off flashlight when component unmounts
            }
        };
    }, [mediaStream]);

    const toggle = () => {
        setCropped(true)
    }

    const handleTakePhoto = (dataUri) => {
        setCapturedImage(dataUri)
        flashLight && torchMechanism(mediaStream, false)
        setTimeout(() => {
            setCamera(false)
        }, 400)
    }

    const torchMechanism = (stream, flashLight) => {
        if (stream) {
            const track = stream.getVideoTracks()[0];
            if (track) {
                track.applyConstraints({
                    advanced: [{ torch: flashLight }]
                }).then(() => {
                    console.log("Torch constraint applied successfully");
                }).catch((e) => {
                    console.log("Failed to apply torch constraint:");
                });
            } else {
                console.warn("No video track found");
            }
        }
    };

    const handleFrontCamera = () => {
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                const videoDevices = devices.filter(device => device.kind === 'videoinput');

                const frontCamera = videoDevices.find(device => device.label.toLowerCase().includes('front'));
                if (frontCamera) {
                    setFacialMode(frontCamera.deviceId); // Set the deviceId directly
                } else {
                    setFacialMode(FACING_MODES.ENVIRONMENT); // Fallback to rear camera
                    setFrontCameraError(true)
                }
            })
            .catch(err => console.error("Error enumerating devices: ", err));
    }



    const cameraStarted = (stream) => {
        setStream(stream)
        // handleCameraType()

        handleFrontCamera()
        torchMechanism(stream, flashLight)
        setErrorType({ isError: false, type: "ios" })
    }

    const checkError = () => {
        const browser = GetBrowserDetails();

        if (browser.name === "Mobile Chrome") {
            navigator.permissions.query({ name: 'camera' }).then((res) => {
                if (res.state === "denied") {
                    if (OS === 'iOS') {
                        setErrorType({ isError: true, type: "ios" })
                    } else {
                        setErrorType({ isError: true, type: "android" })
                    }
                }
            }).catch((error) => {
                setErrorType({ isError: true, type: "ios" })
            })

        } else {
            setErrorType({ isError: true, type: "ios" })
        }

    }

    const OvuleShape = () => {
        return (
            <div className="ovule-container h-full w-full flex flex-col justify-center items-center">
                {/* bg-[#808483] */}
                <div className="absolute top-10 font-jost font-normal text-lg rounded-full px-4 py-1 text-[#FFFFFF] border-2 border-white">Tap anywhere to capture</div>
                <div className="relative w-full h-full flex justify-center items-center">
                    {/* Face Outline - using viewport units for responsive sizing */}
                    <div className="absolute inset-0 bg-transparent flex justify-center items-center">
                        <img src={oval} className="w-auto h-[70vh] max-w-[90%] object-contain" />
                    </div>

                    <div className="absolute bottom-20 left-1/2 transform -translate-x-1/2 bg-[#808483] border-2 border-white text-xs font-jost text-center text-[#FFFFFF] w-60 font-semibold rounded-lg px-4 py-3 sm600:text-2xl sm600:w-full">
                        Align your head, smile and tap the screen to snap the pic.
                    </div>
                </div>
            </div>
        );
    };


    const TopLayer = () => {
        return (
            <div className=' top-0 left-0 bg-slate-600 text-white w-full  z-50'>
                {/* Back Button Top Left */}
                <div className='absolute top-0 left-0 h-13 p-4'>
                    <button type='button' onClick={() => { window.history.back() }}>
                        <img src={arrow1} alt="back" className="w-8 h-8" />
                    </button>
                </div>

                {/* Back Button Top Right */}
                <div className='absolute top-0 right-0 h-13 p-4'>
                    <button type='button' onClick={() => { window.history.back() }}>
                        <img src={arrow2} alt="back" className="w-8 h-8" />
                    </button>
                </div>

                {/* Back Button Bottom Left */}
                <div className='absolute bottom-0 left-0 p-4'>
                    <button type='button' onClick={() => { window.history.back() }}>
                        <img src={arrow3} alt="back" className="w-8 h-8" />
                    </button>
                </div>

                {/* Back Button Bottom Right */}
                <div className='absolute bottom-0 right-0  p-4'>
                    <button type='button' onClick={() => { window.history.back() }}>
                        <img src={arrow4} alt="back" className="w-8 h-8 " />
                    </button>
                </div>
            </div>


        )
    }


    return (
        <>
            {isDemo ? <DemoScreens activeImageSide={activeImageSide} setToothPicker={setToothPicker} setDemo={setDemo} setCamera={setCamera} quickScan={quickScan} /> :
                <div className='h-full'>
                    {errorType.isError && <PermissionPopup info={errorType} setErrorType={setErrorType} />}
                    {
                        isCamera ?
                            <>
                                <Camera
                                    onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                                    idealFacingMode={facialMode} // Set back camera preference
                                    isFullscreen={false}
                                    isMaxResolution={true}
                                    imageType={IMAGE_TYPES.JPG}
                                    imageCompression={0.9}
                                    sizeFactor={1}
                                    videoConstraints={{
                                        facingMode: { exact: "user" }, // Force front camera
                                        width: { ideal: window.innerWidth },
                                        height: { ideal: window.innerHeight },
                                        aspectRatio: { ideal: window.innerWidth / window.innerHeight }
                                    }}
                                    isImageMirror={facialMode !== FACING_MODES.ENVIRONMENT}
                                    onCameraStart={cameraStarted}
                                    onCameraError={checkError}
                                    className="WebCam"
                                />


                                {!errorType.isError && frontCameraError && <FrontCameraError back={setFrontCameraError} />}

                            </> : <Preview setImageSide={setImageSide} image={capturedImage} setCamera={setCamera} setToothPicker={setToothPicker} activeImageSide={activeImageSide} setDemo={setDemo} quickScan={quickScan} onClose={toggle} />
                    }
                    {
                        isCamera && <div><OvuleShape />
                            <TopLayer /></div>
                    }

                </div >
            }
        </>
    )
}

export default Shoot