import { Routes, Route, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import OnBoarding from './Components/Onboarding';
import FullScan from './Components/Scan/fullScan';
import Landscape from './Components/Landscape';
import PageNotFound from './Components/PageNotFound';
import TpScanLanding from './Components/Landing/tpscanLanding'
import BernFlow from './Components/Bearn/bernFlow';
import ScFullScan from './Components/Scan/scFullScan';
import { getDesktopOrMobile, getIpadFlow } from './Utils/deviceDetails';
import QrCode from './Components/QrCode';
import ScOnBoarding from './Components/ScOnboarding';
import Simmetry from "./Components/BearnSimmetry"
import './App.css';
import { useLocation } from 'react-router-dom';
import SimmetryIpad from './Components/BearnIpadSimmetry';

const App = () => {

  const [isDesktop, setDeviceType] = useState(false)
  const [isLandscape, setLandscape] = useState(window.innerWidth > window.innerHeight)

  const location = useLocation();
  const [isIpad, setIsIpad] = useState(() => {
    const pathSegments = window.location.pathname.split('/').filter(Boolean);
    return pathSegments.includes("sim-ipad");
  });



  const handleOrientation = () => {
    detectDevice()
    setLandscape(window.innerWidth > window.innerHeight)
    document.documentElement.style.setProperty("--browser-offset", `calc(var(--vh, 1vh) * 100 - ${window.innerHeight}px)`);
  }

  const detectDevice = () => {
    const isDesktopWindows = getDesktopOrMobile()
    setDeviceType(isDesktopWindows)
  }

  useEffect(() => {
    window.addEventListener("resize", handleOrientation)
    handleOrientation()
    // window.open()
    return () => {
      window.removeEventListener("resize", handleOrientation)
    }
  }, [])


  return (
    <div className="App">
      {

        isDesktop && isIpad && getIpadFlow() ?
          <Routes>
            <Route path="/" element={<QrCode />} />
            <Route path="*" element={<QrCode />} />
          </Routes> :
          isLandscape ? <Landscape /> :
            <Routes>
              <Route exact path="/" element={<Navigate to={`/default`} />} />
              <Route path="/onboarding" element={<OnBoarding />} />
              <Route path="/sc/onboarding" element={<ScOnBoarding />} />
              <Route path="/sc/:uid" element={<TpScanLanding />} />
              <Route path="/full-scan" element={<FullScan />} />
              <Route path="/sc/full-scan" element={<ScFullScan />} />
              <Route path="/report" element={<BernFlow />} />
              <Route path="/:uid" element={<TpScanLanding />} />
              <Route path="*" element={<PageNotFound />} />
              <Route path='/sim/*' element={<Simmetry />} />
              {isIpad && <Route path='/sim-ipad/*' element={<SimmetryIpad />} />}

            </Routes>
      }
    </div >
  );
}

export default App;