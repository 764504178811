import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { simmetryUserDetailsPayload } from "../../../Services/APIs/scan";
import { postLogDetails } from "../../../Services/APIs/appDetails";

function AboutMeForm({ toggleActive }) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [dataReceived, setDataReceived] = useState(false); // Track if data is received
    let logCount = 0;




    const uid = (() => {
        try {
            const storedUser = localStorage.getItem("usertpi");
            return storedUser ? JSON.parse(storedUser)?.usertpi : null;
        } catch (e) {
            console.error("Error parsing user data from localStorage:", e);
            return null;
        }
    })();

    useEffect(() => {
        postLogDetails({ app: "simmetry-ipad", payLoad: "On form screen", uid })

    }, [])

    useEffect(() => {
        const handleMessage = (event) => {
            const trustedOrigin = "https://www.smilesimulator.co"; // Ensure this is correct

            if (event.origin !== trustedOrigin) {
                console.warn("Blocked message from untrusted origin:", event.origin);
                return;
            }

            try {
                if (typeof event.data === "string") {
                    const data = JSON.parse(event.data);

                    if (data?.result === true) {
                        localStorage.setItem("is_form", "true");
                        setTimeout(() => {
                            toggleActive();
                        }, 100); // Delay to ensure state updates
                    }
                }
            } catch (error) {
                console.warn("Error parsing message from iframe:", error);
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);


    return (
        <>
            <iframe
                src={`https://www.smilesimulator.co/form/smileexpress?temp_image_id=${uid}`}
                title="Form Iframe"
                width="100%"

                style={{ border: "none" }}
            />
        </>
    );
}

export default AboutMeForm;
