import { useNavigate, useParams } from "react-router-dom"
import close from "../../../assets/Icons/close.png"
import ErrorImg from "../../../assets/ReShootErrorImage/error.jpg"
import invalidImage from "../../../assets/ReShootErrorImage/invalid-image.jpg"
import poorLight from "../../../assets/ReShootErrorImage/poor-light.jpg"
import aiError from "../../../assets/simmetry/simAiError.png"
// import "../TermsAndConds/index.css"

const ReshootPopup = (props) => {
    const { dentalOfficeName } = useParams();

    const { setReshoot, errorType, setCamera, setDemo } = props
    const navigate = useNavigate()
    const company_style_details = JSON.parse(localStorage.getItem("company_style_details"));
    const fontWeight = { fontWeight: company_style_details ? `${company_style_details.onboarding_text_font_weight}` : 900 };
    const buttonStyle = {
        backgroundColor: company_style_details
            ? `${company_style_details.button_background_color}`
            : "#000000",
    };

    const Failed = () => {
        return (
            <div className="h-full w-full absolute top-0 z-10 bg-black/[0.4] backdrop-blur-sm flex justify-center items-center" >
                <div className="bg-white rounded-xl py-7 px-5 w-10/12 max-w-96">
                    <div className="flex pt-4 justify-center items-start relative">
                        <img src={aiError} alt="Error" className=" h-24 sm600:h-48" />
                        <button onClick={() => {
                            setReshoot(false)
                        }} className="absolute top-2 right-2">
                            <img src={close} alt="close" className=" h-5 sm600:h-10" />
                        </button>
                    </div>
                    <p className=" text-center  font-jost text-xl text-[#011E41]  py-4 font-medium text-[17px] tracking-[4%]">ERROR!</p>

                    <div className="font-jost text-base text-[#022022] p-4 text-center" >
                        <p className=" text-base text-[#022022] font-jost">AI is currently down and please proceed the scan later.</p>

                    </div>

                    <div className="flex flex-col items-center mt-3">

                        <button
                            type="button"
                            className=" w-52  rounded-full  text-white bg-[#011E41]   font-jost py-4 px-2  font-medium text-[17px] tracking-[4%]"
                            onClick={() => {
                                setCamera(true)
                            }}>
                            RETAKE SCAN
                        </button>
                    </div>
                </div>
            </div>
        )
    }



    const Blurness = () => {

        return (
            <div className="h-full w-full absolute top-0 z-10 bg-black/[0.4] backdrop-blur-sm flex justify-center items-center" >
                <div className="bg-white rounded-xl py-7 px-5 w-10/12 max-w-96">
                    <div className="flex justify-center items-start relative">
                        <img src={ErrorImg} alt="Error" className=" h-24 sm600:h-48" />
                        <button onClick={() => {
                            setReshoot(false)
                        }} className="absolute top-2 right-2">
                            <img src={close} alt="close" className=" h-5 sm600:h-10" />
                        </button>
                    </div>
                    <p className=" text-center  font-jost text-xl text-[#011E41]  py-2 font-medium text-[17px] tracking-[4%]">ERROR!</p>

                    <div className="font-jost text-base text-[#022022] p-4" >
                        <p className=" sm600:text-2xl text-[#022022] font-jost">Improper photo, this may be due to</p>
                        <ul>
                            <li className="flex mt-2">
                                <img src={poorLight} className=" h-6 me-2" alt="ibvalid" />
                                <p className=" sm600:text-2xl text-[#022022]  font-jost"> Poor lighting </p>
                            </li>
                            <li className="flex mt-2">
                                <img src={invalidImage} className="h-6 me-2" alt="poor loght" />
                                <p className=" sm600:text-2xl text-[#022022] font-jost"> The image does-not match the example image</p>
                            </li>
                        </ul>
                    </div>

                    <div className="flex flex-col items-center mt-3">
                        <button
                            type="button"
                            className=" w-52  rounded-full  text-white bg-[#011E41]   font-jost py-4 px-2  font-medium text-[17px] tracking-[4%]"
                            onClick={() => {
                                setDemo(true)
                            }}>
                            VIEW EXAMPLE
                        </button>
                        <button
                            type="button"
                            className="w-52 mt-4 rounded-full font-jost text-[#011E41] py-4 px-4 bg-[#EDE85E]  font-medium text-[17px] tracking-[4%] "
                            onClick={() => {
                                setCamera(true)
                            }}>
                            RETAKE SCAN
                        </button>
                    </div>
                </div>
            </div>
        )



    }





    return (
        <div className="h-full z-20 absolute w-full top-0 bg-black/[0.3] backdrop-blur-sm flex items-end">

            {errorType === "ML failure" ? <Failed /> : <Blurness />}
        </div>
    )
}

export default ReshootPopup