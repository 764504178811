import React, { useState } from "react";
import poorTeeth from "../../assets/bernImages/poorTeeth.png";
import teeth10 from "../../assets/bernImages/10.png";
import teeth20 from "../../assets/bernImages/20.png";
import teeth30 from "../../assets/bernImages/30.png";
import teeth40 from "../../assets/bernImages/40.png";
import teeth50 from "../../assets/bernImages/50.png";
import teeth60 from "../../assets/bernImages/60.png";
import teeth80 from "../../assets/bernImages/80.png";
import {
  formattedDate,
  formatDateToMMDDYY,
} from "../../Utils/getFormattedDate";
import { fontWeight, fontStyle } from "../../Utils/tpscanStyle";

const SemiCircleSpeedometer = () => {
  const [speed, setSpeed] = useState(JSON.parse(localStorage.getItem("tpscore")).overall_score); // Speed state
  const maxSpeed = 100; // Maximum speed the gauge can display
  const size = 300; // Size of the SVG
  const strokeWidth = 25; // Width of the gauge arc
  const center = size / 2; // Center point of the gauge
  const radius = center - strokeWidth; // Radius of the gauge
  const circumference = Math.PI * radius; // Circumference of the semi-circle
  // Calculate the arc length based on the current speed
  const arcLength = (speed / maxSpeed) * circumference;
  // Function to calculate the image position
  const getImagePosition = () => {
    const angle = (2 * Math.PI) / 2 + Math.PI * (speed / maxSpeed); // Angle based on speed
    const x = center + (radius + strokeWidth / 2) * Math.cos(angle); // Position outside the arc
    const y = center - (radius + strokeWidth / 2) * Math.sin(-angle); // Y is inverted due to SVG coordinate system
    return { x, y };
  };

  const { x: imageX, y: imageY } = getImagePosition();
  switch (true) {
    case speed == 0:
      var imgsrc = poorTeeth;
      break;
    case speed == 10:
      var imgsrc = teeth10;
      break;
    case speed == 20:
      var imgsrc = teeth20;
      break;
    case speed == 30:
      var imgsrc = teeth30;
      break;
    case speed == 40:
      var imgsrc = teeth40;
      break;
    case speed == 50:
      var imgsrc = teeth50;
      break;
    case speed == 60:
      var imgsrc = teeth60;
      break;
    case speed == 80:
      var imgsrc = teeth80;
      break;
    case speed == 70:
      var imgsrc = teeth60;
      break;
    default:
      var imgsrc = teeth80;
  }

  return (
    <div>
      <p
        className="font-semibold  text-base px-3 mt-3"
        style={{ ...fontStyle, ...fontWeight, fontSize: "16px", wordSpacing: "2px" }}
      >
        Here's your overall Oral Health Score {formatDateToMMDDYY()}
      </p>
      <div
        className="my-1 px-3  opacity-50"
        style={{ ...fontStyle, fontSize: "12px" }}
      >
        This report has been sent to your email.
      </div>
      <div className=" flex flex-col justify-center items-center my-3">
        <div style={{ textAlign: "center", position: "relative" }}>
          <svg width={size} height={size / 2} style={{ overflow: "visible" }}>
            <defs>
              <linearGradient
                id="gradientStroke"
                x1="0%"
                y1="100%"
                x2="100%"
                y2="0%"
              >
                <stop offset="20%" stopColor="#F74F07" />{" "}
                {/* Start color: red */}
                <stop offset="50%" stopColor="#F7A307" />
                <stop offset="80%" stopColor="#85B915" />
                <stop offset="90%" stopColor="#13D022" />{" "}
                {/* End color: green */}
              </linearGradient>
            </defs>
            {/* Fullground semi-circle */}
            <path
              d={`M ${center - radius},${center} A ${radius},${radius} 0 0,1 ${center + radius
                },${center}`}
              stroke="#F6F4F4"
              strokeWidth={strokeWidth}
              fill="none"
              strokeLinecap="round"
            />
            {/* Foreground semi-circle */}
            <path
              d={`M ${center - radius},${center} A ${radius},${radius} 0 0,1 ${center + radius
                },${center}`}
              stroke="url(#gradientStroke)"
              strokeWidth={strokeWidth}
              fill="none"
              strokeDasharray={circumference}
              strokeDashoffset={circumference - arcLength}
              strokeLinecap="round"
            />
          </svg>
          <img
            src={imgsrc}
            alt="Speedometer End"
            className="-mt-2"
            style={
              speed < 65
                ? {
                  position: "absolute",
                  left: imageX, // Adjust to center the image
                  top: imageY, // Adjust to center the image
                  width: "50px", // Adjust the size of the image
                  height: "50px", // Adjust the size of the image
                  transform: "translate(-40%, -10%)", // Center the image based on its position
                  zIndex: 10, // Ensure the image is above other elements
                }
                : {
                  position: "absolute",
                  left: imageX, // Adjust to center the image
                  top: imageY, // Adjust to center the image
                  width: "50px", // Adjust the size of the image
                  height: "50px", // Adjust the size of the image
                  transform: "translate(-80%, -10%)", // Center the image based on its position
                  zIndex: 10, // Ensure the image is above other elements
                }
            }
          />
          <div style={{ margin: "20px" }}>
            {/* <input
            type="text"
            min="0"
            max={maxSpeed}
            value={speed}
            onChange={(e) => setSpeed(Number(e.target.value))}
            style={{ width: "100%" }}
          />   */}
            <p
              className="text-center -mt-20 z-50"
              style={{ fontFamily: "Inter, sans-serif" }}
            >
              <span
                className=" font-medium text-black"
                style={{ fontSize: "43px" }}
              >
                {speed}
              </span>
              <span className=" text-3xl tex" style={{ color: "#13D022" }}>
                /100
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SemiCircleSpeedometer;
